<template>
  <img
    width="30px"
    height="30px"
    :src="logo"
    alt=""
  >
</template>
<script>
import Logo from '@/assets/images/logo/fav.svg'

export default {
    data() {
        return {
            logo: Logo,
        }
    },
}
</script>
